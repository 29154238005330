.storeList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
}

.storeList__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    background-color: #f5f5f5;
    margin: 2rem;
}

.storeList button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 3rem;
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid rgb(206, 64, 64);
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.storeList button:hover {
    transform: scale(1.03);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26), 0 0.5rem 1rem rgba(0, 0, 0, 0.26);
}

.storeList__wallet { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.storeList__wallet__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    padding: 1rem;
    border: 1px solid rgb(206, 64, 64);
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

    background-color: #e9e9e9;

    width: 100%;
    height: 100%;

    margin: 1rem 0;
}

.storeList__wallet__content__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin: 0.5rem 1rem;

    color: rgb(0, 0, 0);
}

.storeList__wallet__content__item img {
    width: 2rem;
    height: 2rem;
    filter: invert(100%);
}

.storeList__wallet__content__item p {
    font-size: 1.5rem;
}

.storeList__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1rem;
    width: 100%;
    padding: 4rem 3rem;

    /* scroll flow */
    overflow-y: auto;
    overflow-x: hidden;

    /* scroll bar */
    scrollbar-width: thin;
    scrollbar-color: rgb(206, 64, 64) #f5f5f5;
}

.storeList__body::-webkit-scrollbar {
    width: 0.5rem;
}

.storeList__body::-webkit-scrollbar-track {
    background: #f5f5f5;
}

.storeList__body::-webkit-scrollbar-thumb {
    background-color: rgb(206, 64, 64);
    border-radius: 0.5rem;
    border: 0.5rem solid #f5f5f5;
}

.storeList__body::-webkit-scrollbar-thumb:hover {
    background-color: rgb(206, 64, 64);
}

.storeList__body::-webkit-scrollbar-button {
    display: none;
}

.storeList__body__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.storeList__body__empty h1 {
    font-size: 2rem;
    color: rgb(206, 64, 64);
}

.storeList__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(34, 34, 34);
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}