.marketItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.marketItem:hover {
    transform: scale(1.03);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26), 0 0.5rem 1rem rgba(0, 0, 0, 0.26);
}

.marketItem__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem 0.5rem 0 0;
}

.marketItem__image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.5rem 0.5rem 0 0;
    margin-top: 0.5rem;
}

.marketItem__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.marketItem__info h3 {
    margin: 0.5rem 0;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    color: #333;
}

.marketItem__info h4 {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}

.marketItem__info h5 {
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: rgb(10, 179, 10);
}

#oldPrice {
    text-decoration: line-through;
    color: rgb(179, 10, 10);
    font-size: 1rem;
}

#newPrice {
    color: rgb(10, 179, 10);
    font-size: 1.25rem;
}
